import * as React from "react";
import {
    required,
    List, 
    SimpleShowLayout,
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    SimpleForm,
    TextField, 
    TextInput,
    TopToolbar,
    CreateButton,
    ExportButton,
    BooleanField,
    NumberField,
    NumberInput,
    BooleanInput,
    ReferenceField,
    SingleFieldList,
    ChipField,
} from 'react-admin';
import { Grid } from '@mui/material';
import { BulkDeleteButton } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { JsonInput, JsonField } from "react-admin-json-view";

import Breadcrumb from '../components/breadcrumb';
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const ListActions = () => {
    return (
      <TopToolbar>
        <CreateButton />
        <ExportButton />
        <ImportButton />
      </TopToolbar>
    );
  };

export const TicketTypeList = () => {
    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'Seasons' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List resource="ticket-types" filters={<TicketTypeFilter />} actions={ <ListActions />} title="Seasons" bulkActionButtons={<BulkActionButtons />}>
                        <Datagrid rowClick="show">
                            <TextField source="name" />
                            <TextField source="description" />
                            <TextField source="month" />
                            <TextField source="year" />
                            <ReferenceField label="WP Term" source="term_id" reference="terms">
                                <ChipField color="primary" source="name" />
                            </ReferenceField>
                            <BooleanField source="active" />
                            <EditButton />
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const TicketTypeShow = (props) => (
    <Show {...props} actions={<PostShowActions resource="ticket-types" isEdit={true} />}>
        <Grid container paddingBottom={2} spacing={2}>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="term_id" label="WP Term ID" />
                </SimpleShowLayout>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <TextField source="name" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <TextField source="description" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <TextField source="month" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <TextField source="year" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <BooleanField source="active" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <JsonField
                        source="options"
                        reactJsonOptions={{
                            // Props passed to react-json-view
                            name: null,
                            collapsed: true,
                            enableClipboard: true,
                            displayDataTypes: true,
                        }}
                    />
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);

export const TicketTypeEdit = props => {
    return (
        <Edit {...props}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const TicketTypeCreate = props => {
    return (
        <Create {...props}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    return (
        <SimpleForm {...props}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                    <NumberInput source="term_id" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="name" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="description" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput step={1} min={1} max={12} source="month" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput min={1900} step={1} source="year" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput source="active" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <JsonInput
                        source="options"
                        jsonString={false}
                        reactJsonOptions={{
                            // Props passed to react-json-view
                            name: null,
                            collapsed: true,
                            enableClipboard: true,
                            displayDataTypes: true,
                        }}
                    />
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const TicketTypeFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
    </Filter >
);