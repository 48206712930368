import * as React from "react";
import {
    required,
    useShowController,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    RadioButtonGroupInput,
    ReferenceField,
    BooleanField,
    FormDataConsumer,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    DateField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    BulkDeleteButton,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import { Grid } from '@mui/material';
import Breadcrumb from '../components/breadcrumb';
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const NotificationList = props => (
    <Grid container spacing={2}>
        <Breadcrumb title={ 'Notifications' } />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List filters={<NotificationFilter />} title="Notifications" bulkActionButtons={<BulkActionButtons />}>
                    <Datagrid rowClick="show">
                        <TextField source="name" />
                        <TextField source="message" />
                        <TextField source="type" />
                        <BooleanField source="sent_all" />
                        <TextField source="action" />
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                        <EditButton />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    </Grid>
);

export const NotificationShow = (props) => {
    const {
        record
    } = useShowController(props);
    return (
        <Show {...props} actions={<PostShowActions resource="notifications" isEdit={false} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TextField source="id" />
                        <TextField source="name" />
                        <TextField source="message" />
                        <TextField source="type" />
                        <BooleanField source="sent_all" />
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <ReferenceArrayField label="Receivers" reference="users" source="receivers" link="show">
                                <SingleFieldList>
                                    <ChipField source="fullname" color="secondary" />
                                </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField label="Has Read" reference="users" source="has_read" link="show">
                            <SingleFieldList>
                                <ChipField source="fullname" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <TextField source="action" />
                        {
                            (record && record.action === 'post' &&
                                <ReferenceField label="Post" source="object_id" reference="posts" link="show" >
                                    <ChipField source="title" color="default" />
                                </ReferenceField>) 
                            || (record && record.action === 'event' &&
                                <ReferenceField label="Event" source="object_id" reference="events" link="show" >
                                    <ChipField source="title" color="default" />
                                </ReferenceField>)
                            || (record && record.action === 'partnership' &&
                                <ReferenceField label="Exhibitor" source="object_id" reference="partnerships" link="show" >
                                    <ChipField source="title" color="default" />
                                </ReferenceField>)
                        }
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export const NotificationEdit = props => {
    return (
        <Edit {...props}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const NotificationCreate = props => {
    return (
        <Create {...props}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    return (
        <SimpleForm {...props} defaultValues={{is_push: true, action: 'post', sent_all: true, type: 'push_notification', sender: 'system'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="name" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="message" multiline={true} validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput label="Send All" source="sent_all" />
                </Grid>
                <Grid item xs={12}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (!formData.sent_all &&
                            <ReferenceArrayInput source="receivers" reference="users" perPage={9999}>
                                <AutocompleteArrayInput validate={[required()]} format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
                            </ReferenceArrayInput>)
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12}>
                    <RadioButtonGroupInput source="action" choices={[
                        { id: 'post', name: 'Post' },
                        { id: 'event', name: 'Event' },
                        { id: 'partnership', name: 'Exhibitor' },
                    ]} validate={required()} />
                </Grid>
                
                <Grid item xs={12}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData.action === 'post' &&
                            <ReferenceInput source="object_id" reference="posts" perPage={9999}>
                                <AutocompleteInput validate={[required()]} label="Post" filterToQuery={filterToQuery} optionText={choice => `${choice.title}`} optionValue="id" fullWidth />
                            </ReferenceInput>)
                            || (formData.action === 'partnership' &&
                                <ReferenceInput source="object_id" reference="partnerships" perPage={9999}>
                                    <AutocompleteInput validate={[required()]} label="Exhibitor" filterToQuery={filterToQuery} optionText={choice => `${choice.title}`} optionValue="id" fullWidth />
                                </ReferenceInput>)
                            || (formData.action === 'event' &&
                            <ReferenceInput source="object_id" reference="events" perPage={9999}>
                                <AutocompleteInput validate={[required()]} label="Event" filterToQuery={filterToQuery} optionText={choice => `${choice.title} (${choice.date || 'undefined'})`} optionValue="id" fullWidth />
                            </ReferenceInput>)
                        }
                    </FormDataConsumer>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const NotificationFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
    </Filter >
);

function formatUrl(value) {
    if (!value ||  typeof value === "string") {
      return { url: value };
    } else {
      return value;
    }
}

const formatReferenceDisplay = function (value) {
    return value && value._id ? value._id : value;
};

const filterToQuery = searchText => ({ keyword: searchText });