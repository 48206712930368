import * as React from "react";
import {
    required,
    useShowController,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    SelectInput,
    NumberField,
    ImageInput,
    ImageField,
    FormDataConsumer,
    AutocompleteInput,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    RichTextField,
    BulkDeleteButton,
    FunctionField,
    ChipField,
    ReferenceField,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    NumberInput
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Grid } from '@mui/material';
import { JsonField, JsonInput } from "react-admin-json-view";
import Breadcrumb from '../components/breadcrumb';
import { BLOCKS_CHOICE } from '../constants/constants';
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const BlockList = props => (
    <Grid container spacing={2}>
        <Breadcrumb title={ 'Blocks' } />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List filters={<BlockFilter />} title="Blocks" bulkActionButtons={<BulkActionButtons />}>
                    <Datagrid rowClick="show">
                        <TextField source="name" />
                        <TextField source="code" />
                        <TextField source="priority" />
                        <ReferenceField label="Status" source="status" reference="status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Active' ? 
                                    (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <EditButton />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    </Grid>
);

export const BlockShow = (props) => {
    const {
        record
    } = useShowController(props);

    return (
        <Show {...props} actions={<PostShowActions resource="blocks" isEdit={true} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <TextField source="id" />
                        <TextField source="name" />
                        <TextField source="code" />
                        <TextField source="class" />
                        <TextField source="priority" />
                        <TextField source="type" />
                        <ReferenceField label="Status" source="status" reference="status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Active' ? 
                                    (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <JsonField source="options" addLabel={true} jsonString={false} label="Options" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export const BlockEdit = props => {
    return (
        <Edit {...props}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const BlockCreate = props => {
    return (
        <Create {...props}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    return (
        <SimpleForm {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="name" validate={required()} fullWidth />
                    <TextInput source="code" validate={required()} fullWidth />
                    <SelectInput source="type" choices={BLOCKS_CHOICE} optionText="name" optionValue="id" validate={required()} fullWidth />
                    <ReferenceInput source="status" reference="status">
                        <AutocompleteInput validate={[required()]} filterToQuery={filterToQuery} optionText="title" optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                    <NumberInput source="priority" defaultValue={1} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="class" fullWidth />
                </Grid>
                
                <Grid item xs={12}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData.type === 'normal' &&
                            <JsonInput source="options" label="Options" validate={required()} {...rest} />)
                            || (formData.type === 'array' &&
                                 <ArrayInput source="options" {...rest} fullWidth >
                                    <SimpleFormIterator inline={true} >
                                        <TextInput source="name" />
                                        <TextInput source="code" />
                                        <JsonInput source="data" label="Data" />
                                    </SimpleFormIterator>
                                </ArrayInput>)
                        }
                    </FormDataConsumer>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const BlockFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
    </Filter >
);

const filterToQuery = searchText => ({ keyword: searchText });

function formatUrl(value) {
    if (!value ||  typeof value === "string") {
      return { url: value };
    } else {
      return value;
    }
}