import * as React from "react";
import {
    required,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    RichTextField,
    BulkDeleteButton,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
    DateField,
    ChipField,
    SelectInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import Breadcrumb from '../components/breadcrumb';
import { RichTextInput } from 'ra-input-rich-text';
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const AttendeeList = props => (
    <Grid container spacing={2}>
        <Breadcrumb title={ 'Attendees' } />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List filters={<AttendeeFilter />} { ...props } title="Attendees">
                    <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
                        <TextField source="fullname" />
                        <TextField source="email" />
                        <TextField source="phone" />
                        <ReferenceField label="User" source="user._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Event" source="object_id" reference="events" link={ false } >
                            <TextField source="title" />
                        </ReferenceField>
                        <ReferenceField label="Status" source="status" reference="status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Active' ? 
                                    (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                        <EditButton />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    </Grid>
);

export const AttendeeShow = (props) => {
    return (
        <Show {...props} actions={<PostShowActions resource="attendees" isEdit={true} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                    <TextField source="fullname" />
                        <TextField source="email" />
                        <TextField source="phone" />
                        <ReferenceField label="User" source="user._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Event" source="object_id" reference="events" link={ false } >
                            <TextField source="title" />
                        </ReferenceField>
                        <RichTextField source="notes" />
                        <ReferenceField label="Status" source="status" reference="status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Active' ? 
                                    (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export const AttendeeEdit = props => {
    return (
        <Edit {...props} actions={<PostShowActions resource="attendees" />}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const AttendeeCreate = props => {
    return (
        <Create {...props} actions={<PostShowActions resource="attendees" />}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    const [defaultValues, setDefaultValues] = React.useState({object_type: "event"});
    const handleChange = (
        value,
        record
    ) => {
        if (value && record && record.fullname) {
            setDefaultValues({
                object_type: "event",
                user: value,
                fullname: record.fullname,
                email: record.email,
                phone: record.phone
            })
        }
    };
    return (
        <SimpleForm {...props} defaultValues={ defaultValues }>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReferenceInput source="user" reference="users" perPage={9999} fullWidth>
                        <AutocompleteInput onChange={ handleChange } format={ formatReferenceDisplay } validate={[required()]} filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="fullname" validate={[required()]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="email" validate={[required()]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="phone" validate={[required()]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="object_id" reference="events" perPage={9999}>
                        <AutocompleteInput validate={[required()]} label="Event" filterToQuery={filterToQuery} optionText="title" optionValue="id" />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <RichTextInput rows="6" source="notes" defaultValue="" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="status" reference="status">
                        <AutocompleteInput validate={[required()]} filterToQuery={filterToQuery} optionText="title" optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const AttendeeFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <ReferenceInput source="object_id" filterToQuery={ filterToQuery } reference="events" alwaysOn>
            <SelectInput label="Event" optionText="title" optionValue="id" fullWidth/>
        </ReferenceInput>
    </Filter >
);

const formatReferenceDisplay = function (value) {
    return value && value._id ? value._id : value;
};

const filterToQuery = searchText => ({ keyword: searchText });