import { ArrowLeft } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useCreatePath } from 'react-admin';
import { Chip } from '@mui/material';

const BackButton = ({ resource }) => {
    const createPath = useCreatePath();
    return (
        <Link style={{textDecoration: 'none'}} to={createPath({ resource: resource, type: 'list' })}>
            <Chip icon={<ArrowLeft />} label="Back" />
        </Link>
    )
};

export default BackButton;