import * as React from "react";
import {
    required,
    usePermissions,
    useListContext,
    List, 
    Datagrid, 
    EditButton,
    FilterButton,
    ExportButton,
    CreateButton,
    Show,
    Edit,
    Create,
    Filter,
    SimpleForm,
    TextField, 
    EmailField, 
    TextInput,
    DateInput,
    SelectInput,
    PasswordInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    ReferenceInput,
    ImageInput,
    ImageField,
    SimpleShowLayout,
    ReferenceField,
    DateField,
    TopToolbar,
    Button,
    ReferenceArrayInput,
    SelectArrayInput,
    SingleFieldList,
    ChipField,
    ArrayField,
    FunctionField,
    ReferenceArrayField,
    downloadCSV
} from 'react-admin';
import { BadgeOutlined } from '@mui/icons-material';
import { Grid, Container, } from '@mui/material';
import { BulkDeleteButton } from 'react-admin';
import Breadcrumb from '../components/breadcrumb';
import PostShowActions from "../components/postShowActions";
import * as jsonExport from "jsonexport/dist";
import { ImportButton } from "react-admin-import-csv";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const exporter = elements => {
    const elementsForExport = elements.map(data => {
        let element = {
            id: data.id,
            fullname: data.fullname,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            password: "",
            address: data.address,
            phone: data.phone,
            company: data.company,
            company_address: data.company_address,
            company_type: data.company_type,
            job_type: data.job_type,
            country: data.country,
            role: data.role,
            date_created: data.createdAt,
        }

        return element;
    });
    jsonExport(elementsForExport, {
        headers: ['id', 'fullname', 'firstname', 'lastname', 'email', 'password', 'address', 'phone', 'company', 'company_address', 'company_type', 'job_type', 'country', 'role', 'date_created'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'users'); // download as 'users.csv` file
    });
};

const ListActions = () => {
    const { total, isLoading } = useListContext();
    const { permissions } = usePermissions();
    return (
        <TopToolbar>
            <FilterButton filters={userFilters} />
            <CreateButton />
            {permissions === "admin" && <ExportButton exporter={exporter} disabled={isLoading || total === 0} maxResults={999999} />}
            {permissions === "admin" && <ImportButton />}
            <Button href="#/user-types" startIcon={ <BadgeOutlined /> } label="User Types" />
        </TopToolbar>
    );
}

const userFilters = [
    <TextInput label="Search" source="keyword" alwaysOn />,
    <TextInput label="Company Name" source="company" />,
    <DateInput label="Created At" source="createdAt" />,
    <TextInput label="Email" source="email" />,
    <ReferenceInput source="role" reference="roles">
        <SelectInput optionText="title" optionValue="id" fullWidth/>
    </ReferenceInput>
];

export const UserList = function (props) {
    const { permissions } = usePermissions();

    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'Users' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List {...props} actions={<ListActions />} filters={userFilters} title="User" hasCreate={ permissions == 'admin'} hasEdit={ permissions == 'admin'}>
                        <Datagrid rowClick="show" bulkActionButtons={permissions == 'admin' ? <BulkActionButtons /> : false}>
                            <TextField source="fullname" />
                            <TextField source="phone" />
                            <EmailField source="email" />
                            <TextField source="company" label="Company name" />
                            <TextField source="country" />
                            <ArrayField label="Types" source="types">
                                <SingleFieldList linkType={false}>
                                    <FunctionField render={
                                        record => (<ChipField source="name" color="default" sx={{bgcolor: record.color, color: "white"}} />)
                                    } />
                                </SingleFieldList>
                            </ArrayField>
                            <ReferenceArrayField label="Sale Managers" reference="users" source="sale_managers">
                                <SingleFieldList>
                                    <TextField source="fullname" />
                                    <br />
                                    <TextField source="email" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceField label="Role" source="role" reference="roles" link={ false } >
                                <TextField source="title" />
                            </ReferenceField>
                            <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                            { permissions == 'admin' && <EditButton /> }
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const UserShow = function (props) {
    const { permissions } = usePermissions();

    return (
        <Show {...props} actions={<PostShowActions resource="users" isEdit={permissions == 'admin'} />}>
            <Container>
                <Grid container paddingBottom={2} spacing={2}>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SimpleShowLayout>
                                    <TextField source="id" />
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleShowLayout>
                                    <TextField source="fullname" />
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={6}>
                                <SimpleShowLayout>
                                    <EmailField source="email" />
                                    <TextField source="address" />
                                    <TextField source="company" />
                                    <TextField source="company_address" />
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={6}>
                                <SimpleShowLayout>
                                    <TextField source="phone" />
                                    <TextField source="country" />
                                    <TextField source="company_type" />
                                    <ReferenceArrayField label="Sale Managers" reference="users" source="sale_managers">
                                        <SingleFieldList>
                                            <TextField source="fullname" />
                                            <br />
                                            <TextField source="email" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleShowLayout>
                                    <TextField label="Job Function" source="job_type" />
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleShowLayout>
                                    <ArrayField label="Types" source="types">
                                        <SingleFieldList linkType={false}>
                                            <FunctionField render={
                                                record => (<ChipField source="name" color="default" sx={{bgcolor: record.color, color: "white"}} />)
                                            } />
                                        </SingleFieldList>
                                    </ArrayField>
                                    <ReferenceField label="Role" source="role" reference="roles" link={ false } >
                                        <TextField source="title" />
                                    </ReferenceField>
                                </SimpleShowLayout>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <ImageField source="avatar" title="fullname" emptyText="No Image" />
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Container>
        </Show>
    );
}

const UserEditForm = props => (
    <SimpleForm {...props}>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="lastname" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="firstname" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="email" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="phone" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="address" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput source="country" reference="items" filter={{ type: 'country' }} perPage={9999}>
                            <AutocompleteInput filterToQuery={filterToQuery} optionText="name" optionValue="name" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="company" fullWidth />
                        <ReferenceInput label="Job Function" source="job_type" reference="items" filter={{ type: 'job_type' }} perPage={9999}>
                            <AutocompleteInput filterToQuery={filterToQuery} optionText="name" optionValue="name" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput label="Company Type" source="company_type" reference="items" filter={{ type: 'company_type' }} perPage={9999}>
                            <AutocompleteInput filterToQuery={filterToQuery} optionText="name" optionValue="name" fullWidth />
                        </ReferenceInput>
                        <TextInput source="company_address" fullWidth />
                        <ReferenceArrayInput source="sale_managers" reference="users">
                            <AutocompleteArrayInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`}
                                format={value => value && value.length ? value.map((val) => val && val._id ? val._id : val): []} 
                                optionValue="id" label="Sale Managers" fullWidth />
                        </ReferenceArrayInput>
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordInput label="Password" source="password" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput source="role" reference="roles">
                            <SelectInput validate={[required()]} optionText="title" optionValue="id" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceArrayInput source="types" reference="user-types" perPage={9999}>
                            <SelectArrayInput optionText="name" 
                                format={value => value && value.length ? value.map((val) => val && val._id ? val._id : val): []} 
                                optionValue="id" label="User Type" fullWidth />
                        </ReferenceArrayInput>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <ImageInput source="avatar" format={formatUrl} label="Avatar" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </Grid>
        </Grid>
    </SimpleForm>
);

export const UserEdit = props => {
    return (
        <Edit {...props} actions={<PostShowActions resource="users" />}>
            <UserEditForm />
        </Edit>
    );
}

const UserCreateForm = props => (
    <SimpleForm {...props}>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="lastname" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="firstname" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="email" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="phone" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="address" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput source="country" reference="items" filter={{ type: 'country' }} perPage={9999}>
                            <AutocompleteInput filterToQuery={filterToQuery} optionText="name" optionValue="name" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="company" fullWidth />
                        <ReferenceInput label="Job Function" source="job_type" reference="items" filter={{ type: 'job_type' }} perPage={9999}>
                            <AutocompleteInput filterToQuery={filterToQuery} optionText="name" optionValue="name" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput label="Company Type" source="company_type" reference="items" filter={{ type: 'company_type' }} perPage={9999}>
                            <AutocompleteInput filterToQuery={filterToQuery} optionText="name" optionValue="name" fullWidth />
                        </ReferenceInput>
                        <TextInput source="company_address" fullWidth />
                        <ReferenceArrayInput source="sale_managers" reference="users">
                            <AutocompleteArrayInput optionText={choice => `${choice.fullname} (${choice.email})`}
                                filterToQuery={filterToQuery}
                                format={value => value && value.length ? value.map((val) => val && val._id ? val._id : val): []} 
                                optionValue="id" label="Sale Managers" fullWidth />
                        </ReferenceArrayInput>
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordInput label="Password" source="password" initiallyVisible validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput source="role" reference="roles">
                            <SelectInput optionText="title" optionValue="id" validate={[required()]} fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceArrayInput source="types" reference="user-types" perPage={9999}>
                            <SelectArrayInput optionText="name" optionValue="id" label="User Type" fullWidth />
                        </ReferenceArrayInput>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <ImageInput source="avatar" format={formatUrl} label="Avatar" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </Grid>
        </Grid>
    </SimpleForm>
);

export const UserCreate = props => {
    return (
        <Create {...props} actions={<PostShowActions resource="users" />}>
            <UserCreateForm />
        </Create>
    );
}

export const UserFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <TextInput label="Company Name" source="company" />
        <DateInput label="Created At" source="createdAt" />
        <TextInput label="Email" source="email" />
        <ReferenceInput source="role" reference="roles">
            <SelectInput optionText="title" optionValue="id" fullWidth/>
        </ReferenceInput>
    </Filter >
);

const filterToQuery = searchText => ({ keyword: searchText });

function formatUrl(value) {
    if (!value ||  typeof value === "string") {
      return { src: value, title: 'Avatar' };
    } else {
      return value;
    }
}