import * as React from "react";
import { useFormContext } from "react-hook-form";
import {
    required,
    useRecordContext,
    useListContext,
    usePermissions,
    List, 
    SimpleShowLayout,
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    ArrayField,
    SimpleForm,
    TextField, 
    TextInput,
    SelectInput,
    ReferenceInput,
    TopToolbar,
    CreateButton,
    AutocompleteInput,
    ExportButton,
    ReferenceField,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    DateField,
    FunctionField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    SimpleFormIterator,
    ArrayInput,
    WithRecord,
    BooleanInput,
    BooleanField,
    downloadCSV
} from 'react-admin';
import { Grid, Button, Box, Chip } from '@mui/material';
import { PrintOutlined } from '@mui/icons-material';
import { BulkDeleteButton } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import Breadcrumb from '../components/breadcrumb';
import { TICKET_CODE_VISITOR } from '../constants/constants';
import PostShowActions from "../components/postShowActions";
import SyncButton from "../components/syncButton";
import * as jsonExport from "jsonexport/dist";

const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const exporter = posts => {
    const postsForExport = posts.map(post => {
        let element = {
            id: post.id,
            code: post.code,
            season: post.type.name,
            status: post.status
        }
        //const { code, user, type, status, ...postForExport } = post;
        element.user_fullname = post.user.fullname;
        element.user_email = post.user.email;
        element.user_phone = post.user.phone;
        element.user_company = post.user.company;
        element.user_jobtype = post.user.job_type;
        element.user_role = post.user.role;
        element.user_country = post.user.country;
        element.qr_code_url = apiUrl + '/qr-code?data=' + post.code;
        element.pdf_file_url = apiUrl + '/tickets/pdf/' + post.code;

        return element;
    });
    jsonExport(postsForExport, {
        headers: ['id', 'code', 'user_fullname', 'user_email', 'user_phone', 'user_company', 'user_jobtype', 'user_country', 'user_role', 'season', 'status', 'qr_code_url', 'pdf_file_url'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'tickets'); // download as 'posts.csv` file
    });
};

const ListActions = () => {
    const { total, isLoading } = useListContext();
    const { permissions } = usePermissions();

    return (
      <TopToolbar>
        <CreateButton />
        {permissions === "admin" && <ExportButton exporter={exporter} disabled={isLoading || total === 0} maxResults={999999} />}
        {permissions === "admin" && <ImportButton />}
        {permissions === "admin" && <SyncButton resource="tickets" /> }
      </TopToolbar>
    );
  };

export const TicketList = () => {
    const { permissions } = usePermissions();

    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'Tickets' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List filters={ticketFilters} actions={ <ListActions />} title="Ticket">
                        <Datagrid rowClick="show" bulkActionButtons={permissions == 'admin' ? <BulkActionButtons /> : false}>
                            <TextField source="code" />
                            <ReferenceField label="Season" source="type._id" reference="ticket-types" link={ false } >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="User" source="user._id" reference="users" link="show" >
                                <TextField source="fullname" />
                                <br />
                                <TextField source="email" />
                            </ReferenceField>
                            <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                            <ReferenceArrayField label="Events" reference="events" source="events" link="show">
                                <SingleFieldList>
                                    <ChipField source="title" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <FunctionField label="Number of people included " render={record => record.references ? record.references.length : 0} />
                            <ReferenceField label="Status" source="status" reference="status" link={ false } >
                                <FunctionField label="Status" render={
                                    record => record.id === 'Active' ? 
                                        (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                                } />
                            </ReferenceField>
                            <FunctionField label="Print" render={record => (<Button variant="outlined" startIcon={<PrintOutlined />} href={apiUrl + '/tickets/pdf/' +record.code} target="_blank">Print</Button>)} />
                            <EditButton />
                            
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
        
    );
}

const QRCodeImageField = ({ source }) => {
    const record = useRecordContext();

    return (
        record && record[source] ? <img width="95%" src={apiUrl + '/qr-code?data=' +  record[source]} alt="QR Code" /> : <></>
    );
};

const TagsField = ({ source }) => {
    const record = useRecordContext();
    return (
        <div>
            {
                record && record[source] && record[source].length ? 
                    record[source].map(item => (
                        <Chip sx={{ marginRight: 1, marginBottom: 1}} key={item} label={item} color="secondary" variant="outlined" />
                    ))
                    : <></>
            }
        </div>
    );
};

export const TicketShow = (props) => {
    return (
        <Show {...props} actions={<PostShowActions resource="tickets" isEdit={true} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <TextField source="id" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <SimpleShowLayout>
                        <TextField source="code" />
                        <TextField source="type.name" label="Season" />
                        <TextField source="user.fullname" label="User" />
                    </SimpleShowLayout>
                    <Box ml={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <SimpleShowLayout>
                                    <TextField source="user.email" label="Email" />
                                    <TextField source="user.company" label="Company" />
                                    <TextField source="user.job_type" label="Job Function" />
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={6}>
                                <SimpleShowLayout>
                                    <TextField source="user.phone" label="Phone" />
                                    <TextField source="user.company_type" label="Company Type" />
                                    <TextField source="user.country" label="Country" />
                                </SimpleShowLayout>
                            </Grid>
                        </Grid>
                    </Box>
                    <SimpleShowLayout>
                        <ReferenceArrayField label="Events" reference="events" source="events" link="show">
                            <SingleFieldList>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <TagsField source="purposes_of_visit" label="Purpose of Visit" />
                        <TagsField source="product_types" label="Main area of product interest" />
                        <TagsField source="material_types" label="The Material types interest" />
                        <TagsField source="channels_find_out" label="How did you find out about the show?" />
                        <TagsField source="which_hall" label="Which hall are you interest in?" />
                        <BooleanField source="has_attend" label="Did you attend HawaExpo in the previous year?"/>
                        <ReferenceField label="Status" source="status" reference="status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Active' ? 
                                    (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <WithRecord label="Accompany persons" render={
                            record => 
                            <ArrayField source="references">
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source="code" />
                                    <TextField source="fullname" />
                                    <TextField source="email" />
                                    <TextField source="phone" />
                                    <TextField source="country" />
                                    <TextField source="company" />
                                    <FunctionField label="Print" render={reference => (<Button variant="outlined" startIcon={<PrintOutlined />} href={`${apiUrl}/ticket-pdf/?code=${record.code}&fullname=${reference.fullname}&country=${reference.country || ''}&company=${reference.company || ''}`} target="_blank">Print</Button>)} />
                                </Datagrid>
                            </ArrayField>
                        } />
                        
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={4} marginBottom={5}>
                    <QRCodeImageField source="code"/>
                    <Grid textAlign={"center"}>
                        <FunctionField label="Print" render={record => (<Button variant="outlined" startIcon={<PrintOutlined />} href={apiUrl + '/tickets/pdf/' +record.code} target="_blank">Print</Button>)} />
                    </Grid>
                </Grid>
            </Grid>
        </Show>
    );
}

export const TicketEdit = props => {
    const { permissions } = usePermissions();

    return (
        <Edit {...props} sx={{'.ra-delete-button' : {display: permissions != 'admin' ? 'none' : 'inherit'} }}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

const getTicketCode = () => {
    var code = TICKET_CODE_VISITOR;
    var unix = Math.round(+new Date()/1000);
    var min = 100000;
    var max = 999999;
    var random = Math.floor(Math.random() * (max - min + 1)) + min;

    code = code + random.toString() + unix.toString();

    return code;
}

const createDefaultValue = function() {
    let code = getTicketCode();

    return { 
        code: code
    };
} 


export const TicketCreate = props => {
    var defaultValues = createDefaultValue();
    return (
        <Create {...props}>
            <CreateEditForm {...props} defaultValues={defaultValues} />
        </Create>
    );
}

const RegenerateCodeButton = () => {
    const { setValue, trigger } = useFormContext();

    return (
        <Button
            onClick={() => {
                let code = getTicketCode();
                setValue("code", code);
                trigger(['code']);
            }}
            variant="outlined"
        >
            Re-generate the ticket
        </Button>
    );
}

const PreviewImage = (props) => { 
    const { getValues } = useFormContext();

    return (
        <img width="95%" src={apiUrl + '/qr-code?data=' +  getValues('code')} alt="QR Code" />
    );
}

const CreateEditForm = props => {
    const { defaultValues } = props;
    console.log(defaultValues);
    return (
        <SimpleForm defaultValues={defaultValues}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={10}>
                    <TextInput source="code" aria-readonly={true} validate={required()} fullWidth />
                </Grid>
                <Grid item xs={2} sx={{ pt: "0px !important" }}>
                    <RegenerateCodeButton />
                </Grid>
                <Grid item xs={8}>
                    <ReferenceInput source="type" reference="ticket-types">
                        <SelectInput validate={[required()]} format={ formatReferenceDisplay } label="Season" optionText="name" optionValue="id" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput source="user" reference="users" perPage={9999}>
                        <AutocompleteInput validate={[required()]} format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
                    </ReferenceInput>
                    <ReferenceArrayInput source="purposes_of_visit" reference="items" filter={{ type: 'purpose_of_visit' }} perPage={9999}>
                        <AutocompleteArrayInput filterToQuery={filterToQuery} optionText="name" optionValue="name" label="Purpose of Visit" fullWidth />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="product_types" reference="items" filter={{ type: 'product_type' }} perPage={9999}>
                        <AutocompleteArrayInput filterToQuery={filterToQuery} optionText="name" optionValue="name" label="Main area of product interest *" fullWidth />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="material_types" reference="items" filter={{ type: 'material_type' }} perPage={9999}>
                        <AutocompleteArrayInput filterToQuery={filterToQuery} optionText="name" optionValue="name" label="The Material types interest" fullWidth />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="channels_find_out" reference="items" filter={{ type: 'channel_find_out' }} perPage={9999}>
                        <AutocompleteArrayInput filterToQuery={filterToQuery} optionText="name" optionValue="name" label="How did you find out about the show?" fullWidth />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="which_hall" reference="items" filter={{ type: 'which_hall' }} perPage={9999}>
                        <AutocompleteArrayInput filterToQuery={filterToQuery} optionText="name" optionValue="name" fullWidth label="Which hall are you interest in?" />
                    </ReferenceArrayInput>
                    <BooleanInput source="has_attend" label="Did you attend HawaExpo in the previous year?" />
                    <ReferenceArrayInput label="Events" source="events" reference="events" perPage={9999}>
                        <AutocompleteArrayInput filterToQuery={filterToQuery} optionText={choice => `${choice.title} (${choice.date || 'undefined'})`} optionValue="id" fullWidth />
                    </ReferenceArrayInput>
                    <ReferenceInput source="status" reference="status">
                        <AutocompleteInput validate={[required()]} filterToQuery={filterToQuery} optionText="title" optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                    <PreviewImage label="QRCode" />
                </Grid>
                <Grid item xs={12}>
                    <ArrayInput source="references">
                        <SimpleFormIterator inline>
                            <TextInput source="code" helperText={false} />
                            <TextInput source="fullname" />
                            <TextInput source="email" />
                            <TextInput source="phone" />
                            <ReferenceInput source="country" reference="items" filter={{ type: 'country' }} perPage={9999}>
                                <AutocompleteInput filterToQuery={filterToQuery} optionText="name" optionValue="name" />
                            </ReferenceInput>
                            <TextInput source="company" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const TicketFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <ReferenceInput source="type" reference="ticket-types" label="Season" alwaysOn>
            <SelectInput optionText="name" optionValue="id" fullWidth/>
        </ReferenceInput>
        <ReferenceInput source="user" reference="users" alwaysOn>
            <SelectInput optionText="fullname" optionValue="id" fullWidth/>
        </ReferenceInput>
    </Filter >
);

const formatReferenceDisplay = function (value) {
    return value && value._id ? value._id : value;
};

const filterToQuery = searchText => ({ keyword: searchText });

const ticketFilters = [
    <TextInput label="Search" source="keyword" alwaysOn />,
    <ReferenceInput source="type" reference="ticket-types" alwaysOn>
        <SelectInput optionText="name" label="Season" optionValue="id" fullWidth/>
    </ReferenceInput>,
    <ReferenceInput source="user" reference="users" alwaysOn>
        <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
    </ReferenceInput>
];
