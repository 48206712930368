import * as React from "react";
import {
    List, 
    SimpleShowLayout,
    Datagrid, 
    Show,
    Filter,
    TextField, 
    RichTextField,
    BooleanField,
    TextInput,
    SelectArrayInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    ExportButton,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    UrlField,
    ImageField,
    FunctionField
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import { BulkDeleteButton } from 'react-admin';
import { JsonField } from "react-admin-json-view";
import Breadcrumb from '../components/breadcrumb';
import SyncButton from "../components/syncButton";
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const ListActions = () => {
    return (
      <TopToolbar>
        <ExportButton />
        <SyncButton resource="events" />
      </TopToolbar>
    );
  };

export const EventList = () => {
    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'Events' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List filters={<EventFilter />} empty={ false } actions={ <ListActions />} title="Event" bulkActionButtons={<BulkActionButtons />}>
                        <Datagrid rowClick="show">
                            <TextField source="post_id" />
                            <RichTextField source="title" />
                            <TextField source="date" />
                            <TextField source="time" />
                            <TextField source="location" />
                            <UrlField source="join_link" />
                            <BooleanField source="allow_registration" />
                            <ReferenceArrayField label="Season" reference="terms" source="season">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField label="Event Type" reference="terms" source="event_type" link="show">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField label="Locations" reference="terms" source="location_terms">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceField source="status" reference="post-status">
                                <FunctionField label="Status" render={
                                    record => record.id === 'publish' ? 
                                        (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                                } />
                            </ReferenceField>
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const EventShow = (props) => (
    <Show {...props} actions={<PostShowActions resource="events" isEdit={false} />}>
        <Grid container paddingBottom={2} spacing={2}>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <RichTextField source="title" />
                    <UrlField source="link" />
                    <RichTextField source="slug" />
                    <ReferenceField source="status" reference="post-status">
                        <FunctionField label="Status" render={
                            record => record.id === 'publish' ? 
                                (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                        } />
                    </ReferenceField>
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <TextField source="date" />
                    <TextField source="time" />
                    <TextField source="location" />
                    <UrlField source="join_link" />
                    <BooleanField source="allow_registration" />
                    <ReferenceArrayField label="Season" reference="terms" source="season">
                        <SingleFieldList>
                            <ChipField source="name" color="secondary" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <ReferenceField source="featured_media" reference="media">
                        <ImageField source="source_url" title="Featured" emptyText="No Image" />
                    </ReferenceField>
                </SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SimpleShowLayout>
                            <ReferenceArrayField label="Event Type" reference="terms" source="event_type" link="show">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField label="Locations" reference="terms" source="location_terms">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <RichTextField source="except" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <RichTextField source="content" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
                <SimpleShowLayout>
                    <JsonField 
                        source="meta" 
                        reactJsonOptions={{
                            collapsed: true,
                        }}
                    />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
                <SimpleShowLayout>
                    <JsonField 
                        source="_links" 
                        label="Links" 
                        reactJsonOptions={{
                            collapsed: true,
                        }}
                    />
                </SimpleShowLayout>
            </Grid>
            
        </Grid>
    </Show>
);

export const EventFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <ReferenceArrayInput alwaysOn source="season" reference="terms" filter={{ taxonomy: 'season' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput alwaysOn source="location_terms" reference="terms" filter={{ taxonomy: 'location' }}>
            <SelectArrayInput label="Locations" />
        </ReferenceArrayInput>
        <ReferenceArrayInput alwaysOn source="event_type" reference="terms" filter={{ taxonomy: 'event_type' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceInput alwaysOn source="status" reference="post-status">
            <SelectInput optionText="title" optionValue="id" fullWidth />
        </ReferenceInput>
    </Filter >
);
