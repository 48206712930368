import * as React from "react";
import {
    required,
    useShowController,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    SelectInput,
    NumberField,
    ImageInput,
    ImageField,
    FormDataConsumer,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    NumberInput,
    RichTextField,
    BulkDeleteButton,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Grid } from '@mui/material';
import { JsonField, JsonInput } from "react-admin-json-view";
import Breadcrumb from '../components/breadcrumb';
import { SETTINGS_CHOICE } from '../constants/constants';
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const SettingList = props => (
    <Grid container spacing={2}>
        <Breadcrumb title={ 'Settings' } />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List filters={<SettingFilter />} title="Settings" bulkActionButtons={<BulkActionButtons />}>
                    <Datagrid rowClick="show">
                        <TextField source="name" />
                        <TextField source="group" />
                        <TextField source="code" />
                        <TextField source="type" />
                        <TextField source="value" />
                        <EditButton />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    </Grid>
);

export const SettingShow = (props) => {
    const {
        record
    } = useShowController(props);

    return (
        <Show {...props} actions={<PostShowActions resource="settings" isEdit={false} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <TextField source="id" />
                        <TextField source="name" />
                        <TextField source="code" />
                        <TextField source="group" />
                        <TextField source="type" />
                        {
                            (record.type === 'text' &&
                                <TextField source="value" />)
                            || (record.type === 'number' &&
                                <NumberField source="value" />)
                            || (record.type === 'image' &&
                                <ImageField source="value" title="name" />)
                            || (record.type === 'json' &&
                                <JsonField source="value" addLabel={true} jsonString={false} label="Value" />)
                            || (record.type === 'html' &&
                                <RichTextField source="value" label="Value" />)
                        }
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export const SettingEdit = props => {
    return (
        <Edit {...props}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const SettingCreate = props => {
    return (
        <Create {...props}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    return (
        <SimpleForm {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="name" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="code" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="group" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <SelectInput source="type" choices={SETTINGS_CHOICE} optionText="name" optionValue="id" validate={required()} fullWidth />
                </Grid>
                
                <Grid item xs={12}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData.type === 'text' &&
                            <TextInput source="value" validate={required()} fullWidth {...rest} />)
                            || (formData.type === 'number' &&
                                <NumberInput source="value" validate={required()} fullWidth {...rest} />)
                            || (formData.type === 'image' &&
                                <ImageInput source="value" validate={required()} format={formatUrl} accept="image/*"  {...rest} >
                                    <ImageField source="url" title="name" />
                                </ImageInput>)
                            || (formData.type === 'json' &&
                                <JsonInput source="value" label="Value" validate={required()} {...rest} />)
                            || (formData.type === 'html' &&
                                <RichTextInput rows="3" source="value" label="Value" validate={required()} fullWidth {...rest} />)
                        }
                    </FormDataConsumer>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const SettingFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
    </Filter >
);

function formatUrl(value) {
    if (!value ||  typeof value === "string") {
      return { url: value };
    } else {
      return value;
    }
}