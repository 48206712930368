import * as React from "react";
import {
    List, 
    SimpleShowLayout,
    Datagrid, 
    Show,
    Filter,
    TextField, 
    TextInput,
    TopToolbar,
    ExportButton,
    ReferenceField,
    RadioButtonGroupInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    DateField,
    downloadCSV,
    useListContext
} from 'react-admin';
import { Grid } from '@mui/material';
import { BulkDeleteButton } from 'react-admin';
import { JsonField } from "react-admin-json-view";
import Breadcrumb from '../components/breadcrumb';
import PostShowActions from "../components/postShowActions";
import * as jsonExport from "jsonexport/dist";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const ListActions = () => {
    const { total, isLoading } = useListContext();

    return (
      <TopToolbar>
        <ExportButton exporter={exporter} disabled={isLoading || total === 0} maxResults={999999} />
      </TopToolbar>
    );
};

const exporter = elements => {
    const elementsForExport = elements.map(data => {
        let element = {
            uuid: data.uuid,
            name: data.name,
            platform: data.platform,
            fcm_token: data.fcm_token,
            user_id: data.user ? data.user._id : '',
            user_fullname: data.user ? data.user.fullname : '',
            user_email: data.user ? data.user.email : '',
            user_phone: data.user ? data.user.phone : '',
            user_country: data.user ? data.user.country : '',
            user_role: data.user ? data.user.role : '',
            date_created: data.createdAt,
        }

        return element;
    });
    jsonExport(elementsForExport, {
        headers: ['uuid', 'name', 'platform', 'fcm_token', 'user_id', 'user_fullname', 'user_email', 'user_phone', 'user_country', 'user_role', 'date_created'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'devices'); // download as 'users.csv` file
    });
};

export const DeviceList = () => {
    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'Devices' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List filters={<DeviceFilter />} actions={ <ListActions />} title="Device" bulkActionButtons={<BulkActionButtons />}>
                        <Datagrid rowClick="show">
                            <TextField source="uuid" />
                            <TextField source="platform" />
                            <TextField source="info.model" label="Model" />
                            <TextField source="name" />
                            <ReferenceField label="User" source="user._id" reference="users" link="show" >
                                <TextField source="fullname" />
                                <br />
                                <TextField source="email" />
                            </ReferenceField>
                            <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const DeviceShow = (props) => (
    <Show {...props} actions={<PostShowActions resource="devices" isEdit={false} />}>
        <Grid container paddingBottom={2} spacing={2}>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="uuid" />
                    <TextField source="fcm_token" />
                    <TextField source="ad_uuid" />
                    <TextField source="platform" />
                    <TextField source="name" />
                    <ReferenceField label="User" source="user._id" reference="users" link="show" >
                        <TextField source="fullname" />
                        <br />
                        <TextField source="email" />
                    </ReferenceField>
                    <JsonField 
                        source="info" 
                        reactJsonOptions={{
                            collapsed: false,
                        }}
                    />
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);

const formatReferenceDisplay = function (value) {
    return value && value._id ? value._id : value;
};

const filterToQuery = searchText => ({ keyword: searchText });

export const DeviceFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <RadioButtonGroupInput source="platform" choices={[
            { id: '', name: 'All' },
            { id: 'android', name: 'Android' },
            { id: 'ios', name: 'iOS' },
        ]} alwaysOn />
        <ReferenceInput source="user" reference="users" alwaysOn>
            <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
        </ReferenceInput>
        <ReferenceInput source="role" reference="roles" alwaysOn>
            <SelectInput optionText="title" optionValue="id" fullWidth/>
        </ReferenceInput>
    </Filter >
);
