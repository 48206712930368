import * as React from 'react';
import { Typography, Breadcrumbs, Link, Icon, Grid } from '@mui/material';


const Breadcrumb = ({ title, subMenu = '', subMenuLink = '', heading = 'Home', headingLink = '/' }) => {

    return (
        <Grid marginLeft={1} sx={{marginTop: '0.5em', marginBottom: '0.5em', paddingTop: '8px'}} container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs separator="" aria-label="breadcrumb">
                    <Link style={{textTransform: 'capitalize'}} href={ headingLink } underline="none">
                        <Icon className={`fa fa-sm fa-home`} sx={{marginRight: '5px'}} /> { heading }
                    </Link>
                    {subMenu !== '' &&
                        <Link style={{textTransform: 'capitalize'}} href={ subMenuLink } underline="none">
                            { subMenu }
                        </Link>
                    }
                    <Typography sx={{textTransform: 'capitalize'}} color="textPrimary">{ title }</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
};

export default Breadcrumb;