import * as React from "react";
import {
    required,
    useShowController,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    SelectInput,
    NumberField,
    ImageField,
    FormDataConsumer,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    NumberInput,
    RichTextField,
    BulkDeleteButton,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
    DateField,
    BooleanField,
    ChipField,
    BooleanInput,
    DateTimeInput,
    useUnique,
} from 'react-admin';
import { Grid } from '@mui/material';
import Breadcrumb from '../components/breadcrumb';
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const AppointmentList = props => (
    <Grid container spacing={2}>
        <Breadcrumb title={ 'Appointments' } />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List filters={<AppointmentFilter />} title="Appointments">
                    <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
                        <TextField source="name" />
                        <TextField source="time" />
                        <TextField source="location" />
                        <RichTextField source="description" />
                        <ReferenceField label="User" source="user._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Owner" source="owner._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <DateField label="Date" source="date" showTime={false} emptyText="N/A" />
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                        <EditButton />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    </Grid>
);

export const AppointmentShow = (props) => {
    const {
        record
    } = useShowController(props);

    return (
        <Show {...props} actions={<PostShowActions resource="appointments" isEdit={true} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <TextField source="name" />
                        <TextField source="time" />
                        <TextField source="location" />
                        <RichTextField source="description" />
                        <ReferenceField label="User" source="user._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Owner" source="owner._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <DateField label="Date" source="date" showTime={false} emptyText="N/A" />
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export const AppointmentEdit = props => {
    return (
        <Edit {...props} actions={<PostShowActions resource="appointments" />}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const AppointmentCreate = props => {
    return (
        <Create {...props} actions={<PostShowActions resource="appointments" />}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    return (
        <SimpleForm {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="name" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="time" validate={[required()]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="location" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="user" reference="users" perPage={9999} fullWidth>
                        <AutocompleteInput format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="owner" reference="users" validate={[required()]} perPage={9999} fullWidth>
                        <AutocompleteInput format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline={true} source="description" defaultValue="" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <DateTimeInput source="date" fullWidth />
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const AppointmentFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <ReferenceInput source="owner" reference="users" alwaysOn>
            <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
        </ReferenceInput>
        <ReferenceInput source="user" reference="users" alwaysOn>
            <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
        </ReferenceInput>
    </Filter >
);

const formatReferenceDisplay = function (value) {
    return value && value._id ? value._id : value;
};

const filterToQuery = searchText => ({ keyword: searchText });