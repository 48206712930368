import * as React from "react";
import {
    List, 
    SimpleShowLayout,
    Datagrid, 
    Show,
    Filter,
    TextField, 
    TextInput,
    SelectInput,
    ReferenceInput,
    TopToolbar,
    ExportButton,
    ReferenceField,
    UrlField
} from 'react-admin';
import { Grid } from '@mui/material';
import { BulkDeleteButton } from 'react-admin';
import { JsonField } from "react-admin-json-view";
import Breadcrumb from '../components/breadcrumb';
import SyncButton from "../components/syncButton";
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const ListActions = () => {
    return (
      <TopToolbar>
        <ExportButton />
        <SyncButton resource="terms" />
      </TopToolbar>
    );
  };

export const TermList = () => {
    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'Terms' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List empty={ false } filters={<TermFilter />} actions={ <ListActions />} title="Term" bulkActionButtons={<BulkActionButtons />} sort={{ field: 'taxonomy', order: 'ASC' }}>
                        <Datagrid rowClick="show">
                            <TextField source="term_id" />
                            <TextField source="name" />
                            <TextField source="display_name" />
                            <TextField source="slug" />
                            <ReferenceField label="Taxonomy" source="taxonomy" reference="taxonomies" link={ false } >
                                <TextField source="title" />
                            </ReferenceField>
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const TermShow = (props) => (
    <Show {...props} actions={<PostShowActions resource="terms" isEdit={false} />}>
        <Grid container paddingBottom={2} spacing={2}>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <TextField source="id" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
                <SimpleShowLayout>
                    <TextField source="name" />
                    <TextField source="display_name" />
                    <UrlField source="link" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
                <SimpleShowLayout>
                    <TextField source="slug" />
                    <ReferenceField label="Taxonomy" source="taxonomy" reference="taxonomies" link={ false } >
                        <TextField source="title" />
                    </ReferenceField>
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <TextField source="description" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
                <SimpleShowLayout>
                    <JsonField source="meta" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
                <SimpleShowLayout>
                    <JsonField source="_links" label="Links" />
                </SimpleShowLayout>
            </Grid>
            
        </Grid>
    </Show>
);

export const TermFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <ReferenceInput source="taxonomy" reference="taxonomies" alwaysOn>
            <SelectInput optionText="title" optionValue="id" fullWidth/>
        </ReferenceInput>
    </Filter >
);
