import { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Sync } from '@mui/icons-material';
import { Button } from '@mui/material';

const SyncButton = ({ resource }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const sync = () => {
        setLoading(true);
        notify('Start sync', { type: 'info'});

        dataProvider.sync(resource, { })
            .then(({ data }) => {
                notify('Sync successfully', { type: 'success' });
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }

    if (loading) return (
        <Button
            size="small"
            startIcon={<Sync />}
        >
            Syncing
        </Button>
    )
    
    if (error) return (
        <Button
            size="small"
            onClick={ () => alert(error.message) }
            startIcon={<Sync />}
        >
            Sync Error
        </Button>
    );

    return (
        <Button
            size="small"
            onClick={ sync }
            startIcon={<Sync />}
        >
            Sync
        </Button>
    )
};

export default SyncButton;