import { 
    People, List, ListAlt, ConfirmationNumber, ClassOutlined, ArticleOutlined, 
    CalendarTodayOutlined, BusinessOutlined, SettingsOutlined, CodeOutlined, DevicesOutlined, 
    MessageOutlined, EmojiEventsOutlined, DiscountOutlined, GroupsOutlined, ContactsOutlined,
    CalendarViewDay, AddCommentOutlined
} from '@mui/icons-material';
import { usePermissions } from 'react-admin';

import { Menu } from 'react-admin';

const CustomMenu = () => {
    const { isLoading, permissions } = usePermissions();
    return isLoading
        ? (<div>Loading...</div>)
        : (
            <Menu>
                <Menu.DashboardItem />
                <Menu.Item to="/users" primaryText="Users" leftIcon={<People />}/>
                <Menu.Item to="/benefits" primaryText="Benefits" leftIcon={<EmojiEventsOutlined />}/>
                <Menu.Item to="/coupons" primaryText="Coupons" leftIcon={<DiscountOutlined />}/>
                { permissions === 'admin' ? <Menu.Item to="/requests" primaryText="Requests" leftIcon={<AddCommentOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/contacts" primaryText="Contacts" leftIcon={<ContactsOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/ticket-types" title='Seasons' primaryText="Seasons" leftIcon={<ListAlt />}/> : <></>}
                <Menu.Item to="/tickets" primaryText="Tickets" leftIcon={<ConfirmationNumber />}/>
                { permissions === 'admin' ? <Menu.Item to="/devices" primaryText="Devices" leftIcon={<DevicesOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/notifications" primaryText="Notifications" leftIcon={<MessageOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/events" primaryText="Events" leftIcon={<CalendarTodayOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/attendees" primaryText="Attendees" leftIcon={<GroupsOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/partnerships" primaryText="Exhibitors" leftIcon={<BusinessOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/appointments" primaryText="Appointments" leftIcon={<CalendarViewDay />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/posts" primaryText="Posts" leftIcon={<ArticleOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/terms" primaryText="Terms" leftIcon={<ClassOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/items" primaryText="Items" leftIcon={<List />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/blocks" primaryText="Blocks" leftIcon={<CodeOutlined />}/> : <></>}
                { permissions === 'admin' ? <Menu.Item to="/settings" primaryText="Settings" leftIcon={<SettingsOutlined />}/> : <></>}
            </Menu>
        );
};

export default CustomMenu;