import { TopToolbar, EditButton } from 'react-admin';
import BackButton from './backButton';

const PostShowActions = ({ resource, direction = 'space-between', isEdit = true }) => (
    <TopToolbar sx={{
        justifyContent: direction
    }}>
        <BackButton resource={ resource } />
        { isEdit && <EditButton />}
    </TopToolbar>
);

export default PostShowActions;