export const TICKET_CODE_VISITOR = '01';

export const SETTINGS_CHOICE = [
    { id: 'text', name: 'Text' },
    { id: 'number', name: 'Number' },
    { id: 'image', name: 'Image' },
    { id: 'json', name: 'JSON' },
    { id: 'html', name: 'Rich Text' },
];

export const BENEFITS_CHOICE = [
    { id: 'role', name: 'Role' },
    { id: 'user_type', name: 'User Type' },
    { id: 'user', name: 'User' }
];

export const BLOCKS_CHOICE = [
    { id: 'normal', name: 'Normal' },
    { id: 'array', name: 'Array' },
];

export const REQUESTS_CHOICE = [
    { id: 'benefit_request', name: 'Benefit Request' }
];