import * as React from "react";
import {
    List, 
    SimpleShowLayout,
    Datagrid, 
    Show,
    Filter,
    TextField, 
    TextInput,
    TopToolbar,
    ExportButton,
    SimpleForm,
    NumberField,
    NumberInput,
    Edit,
    Create,
    required,
    CreateButton,
    EditButton,
    useUnique,
} from 'react-admin';
import { ColorField, ColorInput } from 'react-admin-color-picker';
import { Grid } from '@mui/material';
import { BulkDeleteButton } from 'react-admin';
import Breadcrumb from '../components/breadcrumb';
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const ListActions = () => {
    return (
      <TopToolbar>
        <CreateButton />
        <ExportButton />
      </TopToolbar>
    );
  };

export const UserTypeList = () => {
    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'User Types' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List filters={<UserTypeFilter />} actions={ <ListActions />} title="User Type">
                        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <TextField source="description" />
                            <ColorField source="color" />
                            <NumberField source="priority" />
                            <EditButton />
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const UserTypeShow = (props) => (
    <Show {...props} actions={<PostShowActions resource="user-types" isEdit={true} />}>
        <Grid container paddingBottom={2} spacing={2}>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="code" />
                    <TextField source="description" />
                    <ColorField source="color" />
                    <NumberField source="priority" />
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);

export const UserTypeFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
    </Filter >
);

export const UserTypeEdit = props => {
    return (
        <Edit {...props} actions={<PostShowActions resource="user-types" />}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const UserTypeCreate = props => {
    return (
        <Create {...props} actions={<PostShowActions resource="user-types" />}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    const unique = useUnique();
    return (
        <SimpleForm {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="name" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="code" validate={[unique(), required()]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline={true} source="description" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <ColorInput source="color" />
                </Grid>
                <Grid item xs={12}>
                    <NumberInput step={1} source="priority" validate={required()} defaultValue={0} fullWidth />
                </Grid>
            </Grid>
        </SimpleForm>
    );
}
