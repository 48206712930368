import * as React from "react";
import {
    required,
    useShowController,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    SelectInput,
    NumberField,
    ImageField,
    FormDataConsumer,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    NumberInput,
    RichTextField,
    BulkDeleteButton,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
} from 'react-admin';
import { Grid } from '@mui/material';
import Breadcrumb from '../components/breadcrumb';
import { BENEFITS_CHOICE } from '../constants/constants';
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const BenefitList = props => (
    <Grid container spacing={2}>
        <Breadcrumb title={ 'Benefits' } />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List filters={<BenefitFilter />} title="Benefits">
                    <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
                        <TextField source="name" />
                        <TextField source="type" />
                        <FunctionField label="Object" render={
                            record => (record.type === 'role' &&
                                <ReferenceField label="Object" source="object_id" reference="roles" link={ false } >
                                    <TextField source="title" />
                                </ReferenceField>)
                            || (record.type === 'user_type' &&
                                <ReferenceField label="Object" source="object_id" reference="user-types" link={ false } >
                                    <TextField source="name" />
                                </ReferenceField>)
                            || (record.type === 'user' &&
                                <ReferenceField label="Object" source="object_id" reference="users" link="show" >
                                    <TextField source="fullname" />
                                    <br />
                                    <TextField source="email" />
                                </ReferenceField>)
                        } />
                        <ReferenceField label="Season" source="ticket_type._id" reference="ticket-types" link={ false } >
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="priority" />
                        <TextField source="description" />
                        <EditButton />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    </Grid>
);

export const BenefitShow = (props) => {
    const {
        record
    } = useShowController(props);

    return (
        <Show {...props} actions={<PostShowActions resource="benefits" isEdit={true} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <TextField source="name" />
                        <TextField source="type" />
                        {
                            (record.type === 'role' &&
                                <ReferenceField label="Object" source="object_id" reference="roles" link={ false } >
                                    <TextField source="title" />
                                </ReferenceField>)
                            || (record.type === 'user_type' &&
                                <ReferenceField label="Object" source="object_id" reference="user-types" link={ false } >
                                    <TextField source="name" />
                                </ReferenceField>)
                            || (record.type === 'user' &&
                                <ReferenceField label="Object" source="object_id" reference="users" link="show" >
                                    <TextField source="fullname" />
                                    <br />
                                    <TextField source="email" />
                                </ReferenceField>)
                        }
                        <ReferenceField label="Season" source="ticket_type._id" reference="ticket-types" link={ false } >
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="priority" />
                        <TextField source="description" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export const BenefitEdit = props => {
    return (
        <Edit {...props} actions={<PostShowActions resource="benefits" />}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const BenefitCreate = props => {
    return (
        <Create {...props} actions={<PostShowActions resource="benefits" />}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    return (
        <SimpleForm {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="name" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <SelectInput source="type" choices={BENEFITS_CHOICE} optionText="name" optionValue="id" validate={required()} fullWidth />
                </Grid>
                
                <Grid item xs={12}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData.type === 'role' &&
                            <ReferenceInput source="object_id" reference="roles" fullWidth {...rest}>
                                <SelectInput validate={[required()]} optionText="title" optionValue="id" fullWidth />
                            </ReferenceInput>)
                            || (formData.type === 'user_type' &&
                                <ReferenceInput source="object_id" reference="user-types" fullWidth {...rest}>
                                    <SelectInput validate={[required()]} format={ formatReferenceDisplay } label="User Type" optionText="name" optionValue="id" fullWidth />
                                </ReferenceInput>)
                            || (formData.type === 'user' &&
                                <ReferenceInput source="object_id" reference="users" perPage={9999} fullWidth {...rest}>
                                    <AutocompleteInput validate={[required()]} format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
                                </ReferenceInput>)
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="ticket_type" sort={{field: 'active', order: 'DESC'}} reference="ticket-types">
                        <SelectInput validate={[required()]} format={ formatReferenceDisplay } label="Season" optionText="name" optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline={true} source="description" defaultValue="" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <NumberInput step={1} source="priority" validate={required()} defaultValue={0} fullWidth />
                </Grid>
                
            </Grid>
        </SimpleForm>
    );
}

export const BenefitFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <SelectInput source="type" choices={BENEFITS_CHOICE} optionText="name" optionValue="id" alwaysOn />
    </Filter >
);

const formatReferenceDisplay = function (value) {
    return value && value._id ? value._id : value;
};

const filterToQuery = searchText => ({ keyword: searchText });